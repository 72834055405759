<template>
  <footer class="footer">
    <div class="container">
      <v-divider/>
      <div class="content has-text-centered is-size-7">
        <p class="is-marginless"><strong>Rookie.com.pl</strong> &copy; 2013 ― {{ new Date().getFullYear() }}</p>
        <p class="has-text-grey-lighter">{{ $t('component.footer.cipsoftText') }}</p>
      </div>
      <div class="buttons is-centered are-small">
        <template v-for="{ href, icon } in socials" :key="href">
          <a :href="href" target="_blank" class="button">
            <span class="icon">
              <fa-icon :icon="icon"/>
            </span>
          </a>
        </template>
        <a @mouseenter="toggleHref" @mouseleave="toggleHref" role="button" class="button">
          <span class="icon">
            <fa-icon :icon="['fas', 'envelope']"/>
          </span>
        </a>
        <router-link :to="{ name: 'rules' }" class="button">
          <span class="icon">
            <fa-icon :icon="['fas', 'scale-balanced']"/>
          </span>
        </router-link>
        <router-link :to="{ name: 'privacyPolicy' }" class="button">
          <span class="icon">
            <fa-icon :icon="['fas', 'cookie-bite']"/>
          </span>
        </router-link>
        <button @click="scrollToTop" class="button is-primary">
          <span class="icon">
            <fa-icon :icon="['fas', 'arrow-up']"/>
          </span>
        </button>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Divider from '@/components/Divider.vue'

export default defineComponent({
  name: 'v-footer',
  components: {
    'v-divider': Divider
  },
  computed: {
    socials (): { href: string, icon: [string, string] }[] {
      return [
        { href: 'https://instagram.com/rookie.com.pl/', icon: ['fab', 'instagram'] },
        { href: 'https://discord.gg/ymHEkgZ7gJ', icon: ['fab', 'discord'] },
        { href: 'https://m.me/RookieOfficial/', icon: ['fab', 'facebook-messenger'] },
        { href: 'https://www.facebook.com/RookieOfficial', icon: ['fab', 'facebook-f'] }
      ]
    }
  },
  methods: {
    toggleHref (event: Event): void {
      const element = event.target as HTMLElement

      if (element.hasAttribute('href')) {
        element.removeAttribute('href')
      } else {
        element.setAttribute('href', 'lp.moc.eikoor@rotartsinimda:otliam'.split('').reverse().join(''))
      }
    },
    scrollToTop (): void {
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
})
</script>
