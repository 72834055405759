import { auth } from '@/lib/auth'

type Can = (action: string, reference?: any) => boolean

const isAdminBloggerModerator = (roles: string[]): boolean => {
  return roles.includes('admin') || roles.includes('blogger') || roles.includes('moderator')
}

const isAdminModerator = (roles: string[]): boolean => {
  return roles.includes('admin') || roles.includes('moderator')
}

const isAdminBlogger = (roles: string[]): boolean => {
  return roles.includes('admin') || roles.includes('blogger')
}

export const can: Can = (action, reference) => {
  const { id, roles, banned } = auth

  switch (action) {
    case 'blog.new':
      return isAdminBlogger(roles)
    case 'blog.edit':
      return isAdminBloggerModerator(roles) || (roles.includes('user') && reference.user.id === id)
    case 'blog.delete':
      return roles.includes('admin')
    case 'comment.new':
      return !banned && roles.includes('user')
    case 'comment.edit':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && reference.user.id === id && !reference.softDeleted)
    case 'comment.delete':
      return isAdminModerator(roles) && !reference.softDeleted
    case 'thread.pin':
      return isAdminModerator(roles)
    case 'thread.lock':
      return isAdminModerator(roles)
    case 'thread.new':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && !reference.forum.lockedThreads)
    case 'thread.edit':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && reference.user.id === id && !reference.forum.lockedThreads)
    case 'thread.delete':
      return isAdminModerator(roles)
    case 'post.react':
      return roles.includes('user')
    case 'post.new':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && !reference.thread.lockedPosts)
    case 'post.edit':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && reference.user.id === id && !reference.thread.lockedPosts && !reference.softDeleted)
    case 'post.delete':
      return isAdminModerator(roles) && !reference.softDeleted
    case 'image.new':
      return !banned && roles.includes('user')
    case 'image.edit':
      return isAdminModerator(roles) || (!banned && roles.includes('user') && reference.user.id === id)
    case 'image.delete':
      return isAdminModerator(roles)
    default:
      return true
  }
}
