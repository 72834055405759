export default {
  'view.threads.new.title': 'Nowy wątek',

  'view.threads.new.subjectLabel': 'Temat ({min}-{max} znaków)',

  'view.threads.new.forumLabel': 'Forum',
  'view.threads.new.forumContestsEventsOption': 'Konkursy/Wydarzenia',
  'view.threads.new.forumReportsProposalsOption': 'Zgłoszenia/Propozycje',
  'view.threads.new.forumGeneralOption': 'Dział ogólny',
  'view.threads.new.forumScreenshotsVideosOption': 'Screenshoty/Filmiki',
  'view.threads.new.forumTradeOption': 'Handel',
  'view.threads.new.forumHelpQuestionsOption': 'Pomoc/Pytania',
  'view.threads.new.forumMysteriandoOption': 'Mysteriando',
  'view.threads.new.forumHydeparkOption': 'Hydepark',
  'view.threads.new.forumArchiveOption': 'Archiwum',
  'view.threads.new.forumArticlesOption': 'Artykuły',
  'view.threads.new.forumAnnouncementsOption': 'Ogłoszenia',

  'view.threads.new.languageLabel': 'Język',
  'view.threads.new.contentLabel': 'Treść ({min}-{max} znaków)',
  'view.threads.new.previewLabel': 'Podgląd',

  'view.threads.new.previewButton': 'Podgląd',
  'view.threads.new.addThreadButton': 'Dodaj wątek',

  'view.threads.new.addThreadToast': 'Pomyślni dodałeś nowy wątek.'
}
