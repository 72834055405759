export default {
  'view.library.creatures.creature.statsRibbon': 'Statystyki',
  'view.library.creatures.creature.statsExperienceText': 'Doświadczenie',
  'view.library.creatures.creature.statsHitpointsText': 'Życie',
  'view.library.creatures.creature.statsCharmsText': 'Urok',
  'view.library.creatures.creature.statsSpeedText': 'Sport',
  'view.library.creatures.creature.statsArmorText': 'Pancerz',

  'view.library.creatures.creature.lootText': 'Łup',

  'view.library.creatures.creature.reachabilityText': 'Osiągalny',
  'view.library.creatures.creature.reachabilityReachableText': 'Tak',
  'view.library.creatures.creature.reachabilityUnreachableText': 'Nie',

  'view.library.creatures.creature.typeText': 'Typ',
  'view.library.creatures.creature.typeRegularText': 'Regularny',
  'view.library.creatures.creature.typeBossText': 'Boss',
  'view.library.creatures.creature.typeQuestText': 'Questowy',
  'view.library.creatures.creature.typeRemovedText': 'Wycofany',
  'view.library.creatures.creature.typeSummonText': 'Przywołany',

  'view.library.creatures.creature.unknownText': 'Nieznane'
}
