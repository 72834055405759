export default {
  'view.users.user.featuredStatsRankText': 'Rank',
  'view.users.user.featuredStatsActivityPointsText': 'Activity Pts',
  'view.users.user.featuredStatsCreatedAtText': 'Created At',
  'view.users.user.statsRibbon': 'Stats',
  'view.users.user.statsBlogsText': 'Blogs',
  'view.users.user.statsCommentsText': 'Comments',
  'view.users.user.statsThreadsText': 'Threads',
  'view.users.user.statsPostsText': 'Posts',
  'view.users.user.statsImagesText': 'Images',
  'view.users.user.statsWarningLevelText': 'Warning Level',
  'view.users.user.buttonsPostsText': 'Posts',
  'view.users.user.buttonsImagesText': 'Images'
}
