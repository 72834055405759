export default {
  'view.forums.forum.searchLabel': 'Search',

  'view.forums.forum.forumLabel': 'Forum',
  'view.forums.forum.forumAllOption': 'All Forums',

  'view.forums.forum.languageLabel': 'Language',

  'view.forums.forum.sortLabel': 'Sort',
  'view.forums.forum.latestPostAscOption': 'Last Post Created At ― Ascending',
  'view.forums.forum.latestPostDescOption': 'Last Post Created At ― Descending',
  'view.forums.forum.createdAtAscOption': 'Created At ― Ascending',
  'view.forums.forum.createdAtDescOption': 'Created At ― Descending',

  'view.forums.forum.subjectText': 'Subject',
  'view.forums.forum.languageText': 'Language',
  'view.forums.forum.viewsText': 'Views',
  'view.forums.forum.repliesText': 'Replies',
  'view.forums.forum.statusText': 'Status',

  'view.forums.forum.descriptionStartedText': 'started',
  'view.forums.forum.descriptionRepliedText': 'replied',
  'view.forums.forum.descriptionByText': 'by',

  'view.forums.forum.': 'Contests/Events',

  'view.forums.forum.emptyListText': 'Sorry, but no results match your search criteria.'
}
