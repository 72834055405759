export default {
  'view.users.user.featuredStatsRankText': 'Ranga',
  'view.users.user.featuredStatsActivityPointsText': 'Pkt. aktywności',
  'view.users.user.featuredStatsCreatedAtText': 'Dołączył',
  'view.users.user.statsRibbon': 'Statystyki',
  'view.users.user.statsBlogsText': 'Blogi',
  'view.users.user.statsCommentsText': 'Komentarze',
  'view.users.user.statsThreadsText': 'Wątki',
  'view.users.user.statsPostsText': 'Posty',
  'view.users.user.statsImagesText': 'Obrazki',
  'view.users.user.statsWarningLevelText': 'Ostrzeżenia',
  'view.users.user.buttonsPostsText': 'Posty',
  'view.users.user.buttonsImagesText': 'Obrazki'
}
