export default {
  'view.blog.blog.commentsText': 'Comments',
  'view.blog.blog.languageText': 'Language',
  'view.blog.blog.viewsText': 'Views',
  'view.blog.blog.lengthMinText': 'Length [min]',
  'view.blog.blog.commentsCountText': 'Comments ({count})',
  'view.blog.blog.noCommentsText': 'There are no comments right now.',
  'view.blog.blog.commentLabel': 'Comment ({min}-{max} characters)',
  'view.blog.blog.addCommentButton': 'Add Comment',

  'view.blog.blog.deleteBlogConfirm': 'Are you sure you want to delete this blog?',
  'view.blog.blog.deleteBlogToast': 'You have successfully deleted a blog.',

  'view.blog.blog.commentDeletedText': 'This comment has been deleted by a moderator or administrator.',

  'view.blog.blog.updateCommentToast': 'You have successfully updated a comment.',

  'view.blog.blog.deleteCommentConfirm': 'Are you sure you want to delete this comment?',
  'view.blog.blog.deleteCommentToast': 'You have successfully deleted a comment.'
}
