import { App } from 'vue'

import axios from 'axios'

import { auth } from '@/lib/auth'

export default {
  install: (app: App): void => {
    const http = axios.create({
      baseURL: process.env.VUE_APP_API_ENDPOINT
    })

    http.interceptors.request.use(config => {
      const { token } = auth

      if (token) {
        config.headers.setAuthorization(`Bearer ${token}`)
      }

      return config
    })

    app.config.globalProperties.$axios = http
  }
}
