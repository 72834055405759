import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container is-narrow" }
const _hoisted_3 = { class: "columns is-centered" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "is-flex is-align-items-center is-justify-content-center" }
const _hoisted_6 = { class: "has-text-centered is-flex-grow-1 mx-5" }
const _hoisted_7 = { class: "tags is-centered mb-0" }
const _hoisted_8 = { class: "tag is-primary" }
const _hoisted_9 = { class: "has-white-space-nowrap" }
const _hoisted_10 = {
  class: "block has-text-centered",
  style: {"margin":"0.15rem"}
}
const _hoisted_11 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toaster = _resolveComponent("v-toaster")!
  const _component_v_navbar = _resolveComponent("v-navbar")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_spinner = _resolveComponent("v-spinner")!
  const _component_v_state = _resolveComponent("v-state")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_toaster),
    _createVNode(_component_v_navbar),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_state, {
          data: _ctx.charactersOnline && _ctx.boostedCreature && _ctx.latestFansiteContest
        }, {
          resolved: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.slide = (((_ctx.slide - 1) % _ctx.sliderElements.length) + _ctx.sliderElements.length) % _ctx.sliderElements.length; _ctx.slideDirection = 'left'}),
                      class: "icon",
                      role: "button"
                    }, [
                      _createVNode(_component_fa_icon, { icon: ['fas', 'chevron-left'] })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderElements, ({ translationKey, to, value }, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_ctx.slide === index)
                          ? (_openBlock(), _createBlock(_Transition, {
                              key: 0,
                              name: _ctx.transition,
                              mode: "in-out",
                              appear: ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(translationKey)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_9, [
                                    _createVNode(_component_router_link, { to: to }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("strong", null, _toDisplayString(value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["name"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.slide = (_ctx.slide + 1) % _ctx.sliderElements.length; _ctx.slideDirection = 'right'}),
                      class: "icon",
                      role: "button"
                    }, [
                      _createVNode(_component_fa_icon, { icon: ['fas', 'chevron-right'] })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          unresolved: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_v_spinner)
            ])
          ]),
          _: 1
        }, 8, ["data"])
      ])
    ]),
    _createVNode(_component_v_breadcrumbs),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_v_footer)
  ], 64))
}