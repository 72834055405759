export default {
  'view.forums.index.title': 'Forum',

  'view.forums.index.forumText': 'Forum',
  'view.forums.index.threadsText': 'Wątki',
  'view.forums.index.postsText': 'Posty',
  'view.forums.index.statusText': 'Status',

  'view.forums.index.forumContestsEventsText': 'Konkursy/Wydarzenia',
  'view.forums.index.forumReportsProposalsText': 'Zgłoszenia/Propozycje',
  'view.forums.index.forumGeneralText': 'Dział ogólny',
  'view.forums.index.forumScreenshotsVideosText': 'Screenshoty/Filmiki',
  'view.forums.index.forumTradeText': 'Handel',
  'view.forums.index.forumHelpQuestionsText': 'Pomoc/Pytania',
  'view.forums.index.forumMysteriandoText': 'Mysteriando',
  'view.forums.index.forumHydeparkText': 'Hydepark',
  'view.forums.index.forumArchiveText': 'Archiwum',
  'view.forums.index.forumArticlesText': 'Artykuły',
  'view.forums.index.forumAnnouncementsText': 'Ogłoszenia',

  'view.forums.index.latestPostText': 'Ostatni post',
  'view.forums.index.inText': 'w',

  'view.forums.index.emptyForumText': 'Jeszcze nie ma wątków na tym forum.',

  'view.forums.index.topUsersText': 'Topka użytkowników',
  'view.forums.index.topThreadsText': 'Topka wątków',

  'view.forums.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
