export default {
  'view.library.creatures.creature.statsRibbon': 'Statistics',
  'view.library.creatures.creature.statsExperienceText': 'Experience',
  'view.library.creatures.creature.statsHitpointsText': 'Hitpoints',
  'view.library.creatures.creature.statsCharmsText': 'Charms',
  'view.library.creatures.creature.statsSpeedText': 'Speed',
  'view.library.creatures.creature.statsArmorText': 'Armor',

  'view.library.creatures.creature.lootText': 'Loot',

  'view.library.creatures.creature.reachabilityText': 'Reachable',
  'view.library.creatures.creature.reachabilityReachableText': 'Yes',
  'view.library.creatures.creature.reachabilityUnreachableText': 'No',

  'view.library.creatures.creature.typeText': 'Type',
  'view.library.creatures.creature.typeRegularText': 'Regular',
  'view.library.creatures.creature.typeBossText': 'Boss',
  'view.library.creatures.creature.typeQuestText': 'Quest',
  'view.library.creatures.creature.typeRemovedText': 'Removed',
  'view.library.creatures.creature.typeSummonText': 'Summon',

  'view.library.creatures.creature.unknownText': 'Unknown'
}
