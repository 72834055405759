export default {
  'view.library.items.item.categoryText': 'Kategoria',
  'view.library.items.item.categoryContainerText': 'Pojemnik',
  'view.library.items.item.categoryAmuletText': 'Amulet',
  'view.library.items.item.categoryRingText': 'Pierścień',
  'view.library.items.item.categoryHelmetText': 'Hełm',
  'view.library.items.item.categoryArmorText': 'Zbroja',
  'view.library.items.item.categoryLegsText': 'Spodnie',
  'view.library.items.item.categoryBootsText': 'Buty',
  'view.library.items.item.categoryMeleeWeaponText': 'Broń do walki wręcz',
  'view.library.items.item.categoryAxeText': 'Topór',
  'view.library.items.item.categoryClubText': 'Obuch',
  'view.library.items.item.categorySwordText': 'Miecz',
  'view.library.items.item.categoryDistanceWeaponText': 'Broń dystansowa',
  'view.library.items.item.categoryShieldText': 'Tarcza',
  'view.library.items.item.categoryToolText': 'Narzędzie',
  'view.library.items.item.categoryFoodText': 'Jedzenie',
  'view.library.items.item.categoryLiquidText': 'Płyn',
  'view.library.items.item.categoryCreatureProductText': 'Trofeum',

  'view.library.items.item.statsTab': 'Statystyki',

  'view.library.items.item.statsVolumeText': 'Pojemność',
  'view.library.items.item.statsWeightText': 'Waga (oz.)',
  'view.library.items.item.statsProtectionEarthText': 'Ochrona (ziemia)',
  'view.library.items.item.statsProtectionLifeDrainText': 'Ochrona (wyssanie życia)',
  'view.library.items.item.statsProtectionManaDrainText': 'Ochrona (wyssanie many)',
  'view.library.items.item.statsDurationText': 'Czas trwania (sekundy)',
  'view.library.items.item.statsArmorText': 'Pancerz',
  'view.library.items.item.statsAttackText': 'Atak',
  'view.library.items.item.statsDefenseText': 'Obrona',
  'view.library.items.item.statsDefenseModifierText': 'Modyfikator obrony',
  'view.library.items.item.statsRangeText': 'Zasięg',

  'view.library.items.item.lootTab': 'Loot'
}
